<script>
import formatQualification from "../../package/helpers/format-qualification.helpers";

export default {
  methods: { formatQualification },
  props: {
    qualification: {
      type: Object,
      required: true,
    },

    selectedQualification: {
      type: Object,
      required: true,
    },

    userQualification: {
      type: Object,
      default: null,
    },

    qualificationDescription: {
      type: Object,
      required: true,
    },
  },

  computed: {
    isReceivedQualification() {
      return (
        this.userQualification &&
        this.qualification.id < this.userQualification.id
      );
    },

    imageColorPath() {
      return require(`../../assets/qualifications/color/${this.qualification.code}.png`);
    },

    imageColorlessPath() {
      return require(`../../assets/qualifications/colorless/${this.qualification.code}.png`);
    },
  },
};
</script>

<template>
  <div
    class="qualification-item"
    :class="[
      {
        'qualification-item_active':
          this.selectedQualification.id === this.qualification.id,
      },
      { 'qualification-item_dark': isReceivedQualification },
    ]"
  >
    <div
      class="qualification-item__title"
      @click="$emit('selectQualification', qualification)"
    >
      <img class="qualification-item__img-color" :src="imageColorPath" alt="" />
      <img
        class="qualification-item__img-colorless"
        :src="imageColorlessPath"
        alt=""
      />

      <p>
        {{ formatQualification(qualification.code) }}
      </p>
      <div
        v-if="
          (userQualification && qualification.id === userQualification.id) ||
          isReceivedQualification
        "
        class="qualification-item__user-qualification"
      >
        {{
          userQualification && qualification.id === userQualification.id
            ? "Ваша квалификация"
            : "Получена"
        }}
      </div>
    </div>

    <div
      v-if="selectedQualification.id === qualification.id"
      class="qualification-item__info info"
      :class="[
        qualification.code + '__info',
        {
          info_dark: isReceivedQualification,
        },
      ]"
    >
      <ul class="info__requirements">
        <li
          v-for="requirement in qualificationDescription.requirements"
          :key="requirement.title"
        >
          <div>
            <p v-html="requirement.title"></p>
            <span>{{ requirement.value }}</span>
          </div>
        </li>
      </ul>

      <h6>Бонусы</h6>

      <div
        v-for="bonus in qualificationDescription.bonuses"
        :key="bonus.title"
        class="info__bonus"
      >
        <span>{{ bonus.title }}</span>
        <p>{{ bonus.description }}</p>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.qualification-item {
  width: 100%;
  margin: 0 0 $space-s;
  position: relative;

  &__img-colorless {
    display: none;
  }

  &_active {
    .qualification-item__img-colorless {
      display: none;
    }

    .qualification-item__img-color {
      display: block;
    }

    .qualification-item__title {
      > img {
        opacity: 1;
      }
    }
  }

  &_dark {
    .qualification-item__img-color {
      display: none;
    }

    .qualification-item__img-colorless {
      display: block;
    }
  }

  &__title {
    position: relative;
    z-index: 1;
    cursor: pointer;
    border-radius: $space-s;
    height: 60px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 0 24px;
    overflow: hidden;

    > img {
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      width: 100%;
      height: auto;
      z-index: -1;
      margin: auto;
      opacity: 0.5;
    }

    p {
      @include body-1;
      color: $dark-primary;
      display: flex;
      align-items: center;
    }

    span {
      @include caption-1;
      color: $dark-third;
      display: block;
      margin-left: $space-s;
    }
  }

  &__user-qualification {
    @include caption-1;
    color: $dark-second;
  }

  .info {
    padding: $space-l $space-m;
    top: -8px;
    border-radius: 0 0 $space-s $space-s;
    position: relative;
    z-index: 0;
    margin-bottom: -8px;

    &__requirements {
      list-style: none;
      padding: 0;
      margin-bottom: 32px;

      li {
        &:not(:last-child) {
          margin-bottom: 12px;
        }
      }

      div {
        display: flex;
        align-items: baseline;
        justify-content: space-between;
        gap: 8px;

        p {
          @include text-2;
          color: $dark-primary;
          white-space: nowrap;
        }

        span {
          @include text-2;
          color: $dark-primary;
          text-align: end;
        }
      }
    }

    h6 {
      @include text-1;
      color: $dark-primary;
      margin-bottom: 16px;
    }

    &__bonus {
      &:not(:last-child) {
        margin-bottom: 8px;
      }

      span {
        @include text-2;
        color: $dark-fifth;
      }

      p {
        @include body-1;
        color: $dark-primary;
      }
    }

    &_dark {
      background: none;
      background-color: $light-second;
    }
  }
}

.v_president {
  &__info {
    background: #fff0c7;
  }
}

.ambassador {
  &__info {
    background: #f8f8f8;
  }
}

.diamond_elite {
  &__info {
    background: #eefad1;
  }
}

.grand_diamond {
  &__info {
    background: #fdf0e8;
  }
}

.double_diamond {
  &__info {
    background: #eeecfa;
  }
}

.diamond {
  &__info {
    background: #fff9dd;
  }
}

.double_star {
  &__info {
    background: #fdf0e8;
  }
}

.star {
  &__info {
    background: #e1f7ff;
  }
}

.expert {
  &__info {
    background: #eefad1;
  }
}

.start {
  &__info {
    background: #eeecfa;
  }
}
</style>
